var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-item" }, [
    _c("img", {
      staticClass: "cart-item__img",
      attrs: { src: _vm.beatImageOrProducerLogo(_vm.cartItem.beat), alt: "" },
    }),
    _c("div", { staticClass: "wrapper h-textLeft" }, [
      _c("div", { staticClass: "cart-item__beat-name" }, [
        _vm._v(_vm._s(_vm.cartItem.beat.name)),
      ]),
      _c("div", { staticClass: "cart-item__producer-name" }, [
        _vm._v(_vm._s(_vm.cartItem.beat.producer.display_name)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "cart-item__price-wrapper" },
      [
        _vm.isCart
          ? [
              _c("div", { staticClass: "cart-item__license" }, [
                _vm._v(_vm._s(_vm.cartItem.selected_license_option.name)),
              ]),
              _c("div", { staticClass: "cart-item__price" }, [
                _vm.cartItem.selected_license_option.isFree
                  ? _c("span", { staticClass: "cart-item__free-tag" }, [
                      _vm._v("Free!"),
                    ])
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "h-textLineThrough":
                        _vm.cartItem.selected_license_option.isFree,
                    },
                  },
                  [
                    _vm._v(
                      "$" + _vm._s(_vm.cartItem.selected_license_option.price)
                    ),
                  ]
                ),
              ]),
            ]
          : _c("div", { staticClass: "cart-item__price" }, [
              _vm._v("$" + _vm._s(_vm.calculatedPrice)),
            ]),
      ],
      2
    ),
    _vm.isCart
      ? _c(
          "a",
          {
            staticClass: "cart-item__remove",
            on: {
              click: function ($event) {
                return _vm.removeItemFromCart(_vm.cartItem)
              },
            },
          },
          [_c("icon", { attrs: { name: "close", width: "16", height: "16" } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }