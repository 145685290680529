var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart-item" }, [
    _c("img", {
      staticClass: "cart-item__img",
      attrs: {
        src: _vm.beatImageOrProducerLogo(_vm.cartItem.sound_kit),
        alt: "",
      },
    }),
    _c("div", { staticClass: "wrapper h-textLeft" }, [
      _c("div", { staticClass: "cart-item__beat-name" }, [
        _vm._v(_vm._s(_vm.cartItem.sound_kit.name)),
      ]),
      _c("div", { staticClass: "cart-item__producer-name" }, [
        _vm._v("Sound Kit"),
      ]),
    ]),
    _c("div", { staticClass: "cart-item__price-wrapper" }, [
      _c("div", { staticClass: "cart-item__price" }, [
        _vm._v("$" + _vm._s(_vm.cartItem.sound_kit.price)),
      ]),
    ]),
    _vm.isCart
      ? _c(
          "a",
          {
            staticClass: "cart-item__remove",
            on: {
              click: function ($event) {
                return _vm.removeItemFromCart(_vm.cartItem)
              },
            },
          },
          [_c("icon", { attrs: { name: "close", width: "16", height: "16" } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }