var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.beatCartItems.length === 0 && _vm.soundKitCartItems.length === 0
        ? [
            _c("icon", {
              staticClass: "cart__iconEmptyCart",
              attrs: { name: "cart-empty" },
            }),
            _c("cart-heading", {
              attrs: {
                title: "Your cart is empty :(",
                subtitle: "Add items to the cart to get started.",
              },
            }),
            _c("div", { staticClass: "cartNavigation" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn--lg btn--primary",
                  on: {
                    click: function ($event) {
                      return _vm.router.back()
                    },
                  },
                },
                [_vm._v("RETURN TO SHOP")]
              ),
            ]),
          ]
        : [
            !_vm.isAuthenticated
              ? _c(
                  "div",
                  { staticClass: "cartAlert" },
                  [
                    _vm._v(
                      "Would you like to keep records of your transactions for future download of your Purchased files? "
                    ),
                    _c("router-link", { attrs: { to: { name: "Login" } } }, [
                      _vm._v("Login"),
                    ]),
                    _vm._v(" or "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "Signup",
                            query: { account_type: "artist" },
                          },
                        },
                      },
                      [_vm._v("Create")]
                    ),
                    _vm._v(" a free account here."),
                  ],
                  1
                )
              : _vm._e(),
            _c("cart-heading", {
              attrs: {
                title: "Review your order:",
                subtitle:
                  "Review your selection below and follow the next quick steps to complete your order.",
              },
            }),
            _vm.beatCartItems.length > 0
              ? _vm._l(_vm.beatCartItems, function (item) {
                  return _c("cart-item-beat", {
                    key: item.id,
                    attrs: { isCart: true, "cart-item": item },
                  })
                })
              : _vm._e(),
            _vm.soundKitCartItems.length > 0
              ? _vm._l(_vm.soundKitCartItems, function (item) {
                  return _c("cart-item-sound-kit", {
                    key: item.id,
                    attrs: { isCart: true, "cart-item": item },
                  })
                })
              : _vm._e(),
            _c("div", { staticClass: "cartNavigation" }, [
              _c("div", { staticClass: "cartNavigation__total" }, [
                _c("p", [
                  _vm._v("Your total is $" + _vm._s(_vm.cartTotalAmount)),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "cartNavigation__coupon",
                    on: {
                      click: function ($event) {
                        return _vm.SET_ACTIVE_MODAL({
                          modal: _vm.ModalType.Coupon,
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.coupons || _vm.coupons.length < 1
                            ? "Have a coupon code?"
                            : "Have another coupon code?"
                        )
                    ),
                  ]
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn--lg btn--secondary",
                  on: {
                    click: function ($event) {
                      return _vm.SET_CART_STEP(_vm.CartStep.PaymentOptions)
                    },
                  },
                },
                [_vm._v("NEXT STEP")]
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }